import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import SEO from '../components/common/seo';
import { getCategoryColor } from '../components/context/AppContext';
import { Title } from '../components/common/Title';

import styles from './Privacy.module.scss';

export const pageQuery = graphql`
  query PrivacyPageQuery {
    about: wpPage(slug: { eq: "privacy-policy" }) {
      title
      content
    }
  }
`;

const PrivacyPage = ({ data }) => {
  const { about } = data;
  const { content } = about;
  return (
    <>
      <SEO title="Privacy Policy" />
      <Helmet
        bodyAttributes={{
          class: getCategoryColor(),
        }}
      />

      <div className={styles.row}>
        <div className={styles.rowContent}>
          <Title size="large" tagName="h1">
            {about.title}
          </Title>

          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
      </div>
    </>
  );
};
export default PrivacyPage;
